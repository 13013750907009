export const darkTheme = {
    bg: "#0F0F0F",  // Very dark but soft background to reduce strain
    bgLight: "#1A1A1A",  // A smooth dark gray for light containers
    bgLightFixed: "#2E2E2E",  // Slightly brighter than the light background to provide contrast
    primary: "#7F56D9",  // A softer purple to reduce sharp contrast while keeping elegance
    text_primary: "#E0E0E0",  // A lighter but not harsh white for easy readability
    text_secondary: "#A0A0A0",  // Muted light gray for secondary text
    card: "#252525",  // Dark gray for cards to ensure they stand out without sharp contrast
    button: "#3A3A3A",  // A neutral button color that fits the overall dark theme, avoiding too much pop
};

export const lightTheme = {
    bg:"#FFFFFF",
    bgLight: "#f0f0f0",
    bgLightFixed: "#e0e0e0",
    primary:"#be1adb",
    text_primary:"#111111",
    text_secondary:"#48494a",
    card:"#FFFFFF",
    button:"#5c5b5b",
}