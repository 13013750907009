// // Dashboard.jsx

// import React from 'react';
// import { Typography, Button, Row, Col, Card } from 'antd';
// import { FaEthereum } from 'react-icons/fa';
// import { BellOutlined, LineChartOutlined, SafetyOutlined } from '@ant-design/icons';
// import './Dashboard.css';

// const { Title, Paragraph } = Typography;

// const Dashboard = () => {
//   return (
//     <div className="dashboard-container">
//       <section className="hero-section">
//         <Title className="hero-title">Ethereum Deposit Tracker</Title>
//         <Paragraph className="hero-description">
//           A powerful tool to monitor and record ETH deposits on the Beacon Deposit Contract, providing real-time tracking, comprehensive insights, and seamless error handling.
//         </Paragraph>
//         <Button type="primary" size="large" href="https://discord.gg/vMRBy7by" target="_blank" className="cta-button">
//           Join the Discord for Support
//         </Button>
//       </section>

//       <section className="overview-section">
//         <Title level={3}>Overview</Title>
//         <Paragraph>
//           The Ethereum Deposit Tracker enables seamless monitoring of ETH deposits, ensuring accurate tracking and logging for real-time visibility. It’s designed to handle high-volume transactions and provides comprehensive data on each deposit.
//         </Paragraph>
//         <Row gutter={[16, 16]} className="features-row">
//           <Col span={8}>
//             <Card className="feature-card" hoverable>
//               <LineChartOutlined className="feature-icon" />
//               <Title level={4}>Real-Time Monitoring</Title>
//               <Paragraph>
//                 Stay updated with real-time data fetching and processing, powered by Ethereum RPC methods.
//               </Paragraph>
//             </Card>
//           </Col>
//           <Col span={8}>
//             <Card className="feature-card" hoverable>
//               <SafetyOutlined className="feature-icon" />
//               <Title level={4}>Robust Error Handling</Title>
//               <Paragraph>
//                 Built with resilience in mind, the tracker offers robust error handling and logging for seamless monitoring.
//               </Paragraph>
//             </Card>
//           </Col>
//           <Col span={8}>
//             <Card className="feature-card" hoverable>
//               <BellOutlined className="feature-icon" />
//               <Title level={4}>Customizable Alerts</Title>
//               <Paragraph>
//                 Integrate notifications for new deposits, with optional support for Telegram alerts and a Grafana dashboard.
//               </Paragraph>
//             </Card>
//           </Col>
//         </Row>
//       </section>
//     </div>
//   );
// };

// export default Dashboard;


// Dashboard.jsx

import React from 'react';
import { Typography, Button, Row, Col, Card } from 'antd';
import { FaEthereum } from 'react-icons/fa';
import { BellOutlined, LineChartOutlined, SafetyOutlined } from '@ant-design/icons';
import styled, { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from '../utils/Themes'; 

const { Title, Paragraph } = Typography;

const Dashboard = ({ theme }) => {
  // Determine the current theme based on the prop
  const currentTheme = theme === 'dark' ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={currentTheme}>
      <DashboardContainer>
        <HeroSection>
          <FaEthereum className="hero-icon" />
          <Title className="hero-title">Ethereum Deposit Tracker</Title>
          <Paragraph className="hero-description">
            Monitor and record ETH deposits on the Beacon Deposit Contract with real-time tracking, comprehensive insights, and seamless error handling.
          </Paragraph>
        </HeroSection>

        <OverviewSection>
          <Title level={2} className="section-title">Overview</Title>
          <Paragraph className="section-description">
            The Ethereum Deposit Tracker is a robust application designed to monitor ETH deposits on the Beacon Deposit Contract (0x00000000219ab540356cBB839Cbe05303d7705Fa). It provides real-time tracking, detailed logging, and alerting mechanisms to ensure accurate and efficient monitoring of all deposit activities.
          </Paragraph>
        </OverviewSection>

        <FeaturesSection>
          <Title level={2} className="section-title">Key Features</Title>
          <Row gutter={[24, 24]} className="features-row">
            <Col xs={24} sm={12} md={8}>
              <FeatureCard hoverable>
                <LineChartOutlined className="feature-icon" />
                <Title level={4} className="feature-title">Real-Time Monitoring</Title>
                <Paragraph className="feature-description">
                  Continuously track ETH deposits with real-time data fetching and processing using Ethereum RPC methods.
                </Paragraph>
              </FeatureCard>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <FeatureCard hoverable>
                <SafetyOutlined className="feature-icon" />
                <Title level={4} className="feature-title">Robust Error Handling</Title>
                <Paragraph className="feature-description">
                  Ensure reliability with comprehensive error handling and logging mechanisms for seamless operation.
                </Paragraph>
              </FeatureCard>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <FeatureCard hoverable>
                <BellOutlined className="feature-icon" />
                <Title level={4} className="feature-title">Customizable Alerts</Title>
                <Paragraph className="feature-description">
                  Receive notifications for new deposits via Telegram and visualize data with a Grafana dashboard.
                </Paragraph>
              </FeatureCard>
            </Col>
          </Row>
        </FeaturesSection>

        <CallToActionSection>
          <Title level={2} className="section-title">Get Started</Title>
          <Paragraph className="section-description">
            Join our community on Discord to get support, contribute to the project, and stay updated with the latest developments.
          </Paragraph>
        </CallToActionSection>
      </DashboardContainer>
    </ThemeProvider>
  );
};

export default Dashboard;

// Styled Components

const DashboardContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Space Mono', monospace;
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.text_primary};
  height: 100vh;
  overflow-y: auto;
  
  /* Custom scrollbar for better aesthetics */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.bgLight};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.primary};
    border-radius: 4px;
  }
`;

const HeroSection = styled.section`
  text-align: center;
  padding: 60px 20px;
  background-color: ${({ theme }) => theme.bgLightFixed};
  border-radius: 12px;
  margin-bottom: 40px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  .hero-icon {
    font-size: 4rem;
    color: ${({ theme }) => theme.primary};
    margin-bottom: 20px;
  }

  .hero-title {
    font-size: 3rem;
    color: ${({ theme }) => theme.text_primary};
    margin-bottom: 20px;
    
    @media (max-width: 768px){
      font-size: 2rem;
    }
  }

  .hero-description {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.text_secondary};
    max-width: 800px;
    margin: 0 auto 30px auto;
    
    @media (max-width: 768px){
      font-size: 1rem;
    }
  }

  .cta-button {
    background-color: ${({ theme }) => theme.primary};
    border-color: ${({ theme }) => theme.primary};
    font-size: 1rem;
    
    &:hover, &:focus {
      background-color: ${({ theme }) => theme.primary};
      border-color: ${({ theme }) => theme.primary};
      opacity: 0.9;
    }
  }
`;

const OverviewSection = styled.section`
  text-align: center;
  margin-bottom: 60px;

  .section-title {
    color: ${({ theme }) => theme.text_primary};
    margin-bottom: 20px;
  }

  .section-description {
    color: ${({ theme }) => theme.text_secondary};
    font-size: 1.1rem;
    max-width: 1000px;
    margin: 0 auto;
    
    @media (max-width: 768px){
      font-size: 1rem;
    }
  }
`;

const FeaturesSection = styled.section`
  text-align: center;
  margin-bottom: 60px;

  .section-title {
    color: ${({ theme }) => theme.text_primary};
    margin-bottom: 30px;
  }

  .features-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
  }
`;

const FeatureCard = styled(Card)`
  background-color: ${({ theme }) => theme.card};
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-5px);
  }

  .feature-icon {
    font-size: 3rem;
    color: ${({ theme }) => theme.primary};
    margin-bottom: 15px;
  }

  .feature-title {
    color: ${({ theme }) => theme.text_primary};
    margin-bottom: 10px;
    
    @media (max-width: 768px){
      font-size: 1.2rem;
    }
  }

  .feature-description {
    color: ${({ theme }) => theme.text_secondary};
    font-size: 1rem;
    
    @media (max-width: 768px){
      font-size: 0.9rem;
    }
  }
`;

const CallToActionSection = styled.section`
  text-align: center;
  padding: 40px 20px;
  background-color: ${({ theme }) => theme.bgLightFixed};
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  .section-title {
    color: ${({ theme }) => theme.text_primary};
    margin-bottom: 20px;
  }

  .section-description {
    color: ${({ theme }) => theme.text_secondary};
    font-size: 1.1rem;
    max-width: 800px;
    margin: 0 auto 30px auto;
    
    @media (max-width: 768px){
      font-size: 1rem;
    }
  }

  .cta-button {
    background-color: ${({ theme }) => theme.primary};
    border-color: ${({ theme }) => theme.primary};
    font-size: 1rem;
    
    &:hover, &:focus {
      background-color: ${({ theme }) => theme.primary};
      border-color: ${({ theme }) => theme.primary};
      opacity: 0.9;
    }
  }
`;
